import React, { useEffect, useState } from "react";
import {
  responseData,
  ScoreMap,
  TestDataModel,
  TestStepModel,
} from "../utils/types/test";
import TestStepCanidate from "../Templates/TestStepCandidate";
import { apiBaseUrl } from "../utils/environmentDependencies";
import { WelcomePage } from "../Organisms/WelcomePage";
import { End } from "../Organisms/End";
import { CircularProgress, Container, Grid } from "@mui/material";
import AlreadyDone from "../Organisms/AlreadyDone";
import Progress from "../Molecules/Progress";
import FinalizeForm from "../Organisms/FinalizeForm";
import { shuffle } from "../utils/functions";
import { useSearchParams } from "react-router-dom";
import {
  fireGAEvent,
  fireGAPageView,
  GA_CATEGORY_ADVANCE,
} from "../utils/googleAnalytics";
import { PAGE_TITLE } from "../utils/constants";
import AppHeader from "../Atoms/AppHeader";
import { TestStepCompany } from "../Templates/TestStepCompany";

const fetchTestSteps = async (
  testId: number,
  key: string
): Promise<TestDataModel> => {
  // Get test
  let response = await fetch(
    `${apiBaseUrl}/recunited/questionnaire/test/${testId}?key=${key}`
  );
  let data: responseData = await response.json();
  let isCandidate = data.test.test_definition.is_candidate;

  let formStatus = data.test.simplified_form;

  let availableTestSteps: TestStepModel[] =
    data.test.test_definition.test_steps;

  return { availableTestSteps, formStatus, isCandidate };
};

const Questionnaire: React.FC = () => {
  // sets the id of the score on a certain teststep &
  // expressionindex to help us keep track of it
  const setScoreId = (
    stepNumber: number,
    expressionIndex: number,
    scoreID: number
  ) => {
    let newTestSteps = testSteps;
    newTestSteps &&
      (newTestSteps[stepNumber].test_expressions[
        expressionIndex
      ].expression_values.score_id = scoreID);
    setTestSteps(newTestSteps);
  };

  // sets the score on a certain teststep & expressionindex
  // to help us keep track on it
  const setScore = (stepNumber: number, scoreMap: ScoreMap[]) => {
    let newTestSteps = JSON.parse(JSON.stringify(testSteps));
    scoreMap.forEach((element) => {
      newTestSteps &&
        (newTestSteps[stepNumber].test_expressions[
          element.expressionIndex
        ].expression_values.score = element.score);
    });
    setTestSteps(newTestSteps);
  };

  const [searchParams] = useSearchParams();
  const strTestId = searchParams.get("test_id");
  let testId = 0;
  if (strTestId) {
    testId = parseInt(strTestId, 10);
  }
  const strKey = searchParams.get("key");
  const strFromUrl = searchParams.get("from");
  const key = strKey ? strKey : "";
  const fromUrl = strFromUrl ? decodeURIComponent(strFromUrl) : "";
  const strJobId = searchParams.get("job_id");
  const isApplication = searchParams.has("application");
  const jobId = strJobId !== null ? parseInt(strJobId, 10) : undefined;
  const [testSteps, setTestSteps] = useState<TestStepModel[]>();
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [lastLeftOffIndex, setLastLeftOffIndex] = useState<number>(0);
  const [resFormStatus, setResFormStatus] = useState<boolean>();
  const [step, setStep] = useState<number>(0);
  const [appBarInnerRef, setAppBarInnerRef] = useState<HTMLDivElement | null>(
    null
  );
  const [isCandidate, setIsCandidate] = useState<boolean>(false);

  useEffect(() => {
    fireGAEvent(
      GA_CATEGORY_ADVANCE,
      GA_CATEGORY_ADVANCE.actions.goNext,
      `step ${step}`
    );
  }, [step]);

  useEffect(() => {
    document.title = PAGE_TITLE + " | Test";
    fireGAPageView();
    const recallTestSteps = async () => {
      let { availableTestSteps, formStatus, isCandidate } =
        await fetchTestSteps(testId, key);
      setResFormStatus(formStatus);
      let resTestSteps = availableTestSteps;

      setIsCandidate(isCandidate);
      // create array of test steps with already answered questions at the beginning
      // and not answered questions shuffled at the end
      let testStepsAnswered = availableTestSteps.filter(
        (step) => step.test_expressions[0].expression_values.score !== null
      );
      let testStepsUnanswered = availableTestSteps.filter(
        (step) => step.test_expressions[0].expression_values.score === null
      );

      resTestSteps = testStepsAnswered.concat(shuffle(testStepsUnanswered));
      setLastLeftOffIndex(testStepsAnswered.length);
      setTestSteps(resTestSteps);
      setFinishedLoading(true);
    };
    recallTestSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      testSteps &&
      testSteps.length > 0 &&
      lastLeftOffIndex < testSteps.length
    ) {
      setStep(lastLeftOffIndex ? lastLeftOffIndex + 1 : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoading]);
  return (
    <>
      <AppHeader setInnerRef={setAppBarInnerRef} />
      <Container
        maxWidth="md"
        style={
          appBarInnerRef && appBarInnerRef.clientHeight
            ? {
                minHeight: `calc(100vh - ${appBarInnerRef.clientHeight}px)`,
              }
            : {}
        }
      >
        <Grid
          style={
            appBarInnerRef && appBarInnerRef.clientHeight
              ? {
                  minHeight: `calc(100vh - ${appBarInnerRef.clientHeight}px)`,
                }
              : {}
          }
          data-testid="questionnaire"
          container
        >
          {finishedLoading && !testSteps && <AlreadyDone />}
          {finishedLoading && testSteps && step === 0 && (
            <WelcomePage
              data-testid="start"
              step={0}
              setStep={setStep}
              isCandidate={isCandidate}
            />
          )}
          {finishedLoading &&
            testSteps &&
            step === testSteps.length + 1 &&
            isCandidate && (
              <FinalizeForm
                testId={testId}
                step={testSteps.length + 1}
                setStep={setStep}
                jobId={jobId}
                isApplication={isApplication}
                secretKey={key}
              />
            )}
          {isCandidate
            ? finishedLoading &&
              testSteps &&
              step === testSteps.length + 2 && (
                <End
                  testId={testId}
                  secret_key={key}
                  isCandidate={isCandidate}
                  jobId={jobId}
                  fromUrl={fromUrl}
                />
              )
            : finishedLoading &&
              testSteps &&
              step > testSteps.length && (
                <End
                  testId={testId}
                  secret_key={key}
                  isCandidate={isCandidate}
                />
              )}
          {finishedLoading &&
            testSteps &&
            step > 0 &&
            step < testSteps.length + 1 &&
            (isCandidate ? (
              <TestStepCanidate
                testId={testId}
                stepData={testSteps[step - 1]}
                step={step}
                setStep={setStep}
                setScoreId={setScoreId}
                setScore={setScore}
              />
            ) : (
              <TestStepCompany
                testId={testId}
                stepData={testSteps[step - 1]}
                step={step}
                setStep={setStep}
                setScoreId={setScoreId}
                setScore={setScore}
                score={
                  testSteps[step - 1].test_expressions[1].expression_values
                    .score
                }
              />
            ))}
          {!finishedLoading && (
            <Grid
              style={{ textAlign: "center" }}
              alignSelf="center"
              item
              xs={12}
            >
              <CircularProgress size={50} />
            </Grid>
          )}
          {testSteps &&
            testSteps.length > 0 &&
            step > 0 &&
            step < testSteps.length + 1 && (
              <Progress
                step={step}
                stepsTotal={
                  isCandidate ? testSteps.length + 2 : testSteps.length + 1
                }
              />
            )}
        </Grid>
      </Container>
    </>
  );
};

export default Questionnaire;
