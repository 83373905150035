import { useMediaQuery } from "@mui/material";
import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { RadarGraphProps } from "../utils/types/matching";

export const RadarGraph: React.FC<RadarGraphProps> = ({ matchingScores }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

  const data = matchingScores?.map((item) => {
    return {
      fit: item.fit,
      Team: item.matching_score_team,
      Candidate: item.matching_score_candidate,
      fullMark: 100,
    };
  });

  return (
    <ResponsiveContainer width="100%" height={isMobile ? 300 : 450}>
      <RadarChart
        outerRadius={isMobile ? 80 : 160}
        width={500}
        height={500}
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey="fit"
          fontSize="1.2rem"
          fontFamily="Work Sans"
          tick={{ fill: "white" }}
        />
        <Radar
          name="Das Team"
          dataKey="Team"
          stroke="#227671"
          fill="#227671"
          fillOpacity={0.6}
        />
        <Radar
          name="Du"
          dataKey="Candidate"
          stroke="#FFF000"
          fill="#FFF000"
          fillOpacity={0.6}
        />

        <Legend align="center" />
      </RadarChart>
    </ResponsiveContainer>
  );
};
