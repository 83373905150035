import { Divider, Grid, Theme, useMediaQuery, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TestCategoriesModel, TestValuesModel } from "../utils/types/test";
import { ANSWER_SCALE_MAX, ANSWER_SCALE_MIN } from "../utils/constants";
import { Text } from "../Atoms/Typography/Text";
import styles from "../scss/modules/valuedescriptionlist.module.scss";

// Styling
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: 256,
      height: 256,
    },
    [theme.breakpoints.down("sm")]: {
      icon: { width: 64, height: 64 },
    },
  })
);

interface ValueThresholds {
  lowerLimit: number;
  adjective: string;
}

const VALUE_THRESHOLD_TEXT = `Der Wert "VALUE" ist dir ADJECTIVE.`;

const VALUES_THRESHOLDS: ValueThresholds[] = [
  { lowerLimit: 80, adjective: "überaus wichtig" },
  {
    lowerLimit: 60,
    adjective: "sehr wichtig",
  },
  {
    lowerLimit: 40,
    adjective: "eher wichtig",
  },
  { lowerLimit: 20, adjective: "kaum wichtig" },
  { lowerLimit: 0, adjective: "eher unwichtig" },
];

/**
 * function that will return the fitting threshold text, or an empty text if no thresholds match
 * @param score the score of the value
 */
function getClosestValueThreshold(score: number, value: string) {
  let filteredThresholds = VALUES_THRESHOLDS.filter(
    (threshold) =>
      Math.round((100 * score) / (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN)) >=
      threshold.lowerLimit
  );
  if (filteredThresholds.length > 0) {
    let returnedText = VALUE_THRESHOLD_TEXT.replace(
      "ADJECTIVE",
      filteredThresholds[0].adjective
    );
    returnedText = returnedText.replace("VALUE", value);
    return returnedText;
  } else {
    return "";
  }
}

interface ValueDescriptionListProps {
  values?: TestCategoriesModel[];
}

const ValueDescriptionList: React.FC<ValueDescriptionListProps> = ({
  values,
}) => {
  const valuesOnlyArray: TestValuesModel[] = values
    ? values.reduce<TestValuesModel[]>(
        (prev, cur) => prev.concat(cur.values),
        []
      )
    : [];
  valuesOnlyArray.sort((a, b) => b.score - a.score);
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={8} item xs={12}>
      {valuesOnlyArray.map((value, i) => (
        <Grid item container xs={12} spacing={2} key={value.nature_of_value}>
          <Grid
            item
            container
            spacing={6}
            xs={12}
            direction={i % 2 ? "row-reverse" : "row"}
          >
            <Grid item xs>
              <Grid container spacing={2} xs={12}>
                <Grid item alignSelf="center">
                  <Text
                    textClass="caption"
                    color="colored"
                    text={value.caption ? value.caption : ""}
                  />
                  <Text
                    component="span"
                    textClass="subHeader"
                    text={`${value.nature_of_value} - ${Math.round(
                      (100 * value.score) /
                        (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN)
                    )} %`}
                  />
                </Grid>
                <Grid item>
                  <Text
                    textClass="small"
                    text={getClosestValueThreshold(
                      value.score,
                      value.nature_of_value
                    )}
                    color="light"
                  />
                  <Text
                    textClass="small"
                    text={value.description ? value.description : ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!isSmallScreen && (
              <Grid item alignSelf="center">
                <img
                  className={classes.icon}
                  src={`/ValueIcons/${value.nature_of_value}-big.png`}
                  alt={value.nature_of_value}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ul className={styles.infoList}>
                <li key="feeling_good">
                  <Text
                    bold
                    component="span"
                    textClass="small"
                    text="Dir geht es gut, wenn... "
                  />
                  <Text
                    component="span"
                    textClass="small"
                    text={`${value.feeling_good}`}
                  />
                </li>
                <li key="no_go">
                  <Text
                    bold
                    component="span"
                    textClass="small"
                    text="No-Gos: "
                  />
                  <Text
                    component="span"
                    textClass="small"
                    text={`${value.no_go}`}
                  />
                </li>
                <li key="fitting_work_environment">
                  <Text
                    bold
                    component="span"
                    textClass="small"
                    text="Die passende Arbeitsumgebung: "
                  />
                  <Text
                    component="span"
                    textClass="small"
                    text={`${value.fitting_work_environment}`}
                  />
                </li>
              </ul>
            </Grid>
            {i + 1 < valuesOnlyArray.length && (
              <Grid item xs={12}>
                <Divider color="#132b32" light />
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ValueDescriptionList;
