import { Grid } from "@mui/material";
import React from "react";
import { ToAnswerCandidate } from "../Organisms/ToAnswerCandidate";
import { TestStepCandidateProps } from "../utils/types/test";

const TestStepCanidate: React.FC<TestStepCandidateProps> = ({
  testId,
  stepData,
  step,
  setStep,
  setScoreId,
  setScore,
}) => {
  return (
    <Grid item container xs={12}>
      <Grid item spacing={2} container xs={12} alignContent="center">
        <Grid item xs={12}>
          <ToAnswerCandidate
            content={{
              expression1: stepData.test_expressions[0].expression_text,
              expression2: stepData.test_expressions[1].expression_text,
            }}
            step={step}
            setStep={setStep}
            setScore={setScore}
            stepData={stepData}
            testId={testId}
            setScoreId={setScoreId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestStepCanidate;
