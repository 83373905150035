/**
 * minimum value on the answer scale. every integer between (and including) this number and ANSWER_SCALE_MAX will be represented
 */
export const ANSWER_SCALE_MIN = 0;

/**
 * maximum value on the answer scale. every integer between (and including) this number and ANSWER_SCALE_MIN will be represented
 */
export const ANSWER_SCALE_MAX = 7;

/**
 * base page title
 */
export const PAGE_TITLE = "Recunited Wertetest";

/**
 * test tips for teststeps
 */
export const TEST_TIPS_ARR = [
  "Es gibt kein richtig oder falsch, antworte aus dem Bauch heraus",
  "Follow your instincts.",
  "Es ist schwierig, eine Entscheidung zu treffen, aber nur so kann man sich selbst entdecken.",
  "Wir empfehlen dir, spontan zu entscheiden - aus dem Bauch heraus.",
  "Ist das schwierig? Ich weiß, aber deine Wahl führt dich zum Ergebnis!",
];

/**
 * industries for form select
 */
export const INDUSTRIES = [
  "IT und Softwareentwicklung",
  "Vertrieb und Handel",
  "Beratung / Consulting",
  "Projektmanagement",
  "Administration",
  "Sachbearbeitung und Verwaltung",
  "Marketing und Werbung",
  "Management und Unternehmensentwicklung",
  "Kundenbetreuung",
  "Personalwesen und HR",
  "Forschung, Lehre und Entwicklung",
  "Produktmanagement",
  "Finanzen",
  "Rechnungswesen und Controlling",
  "Gesundheit, Medizin und Soziales",
  "PR, Öffentlichkeitsarbeit und Journalismus",
  "Sonstige Tätigkeitsfelder",
];
