import { Alert, Fade, Grid, Grow, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import Expression from "../Atoms/Expression";
import AnswerScale from "../Molecules/AnswerScale";
import { Button } from "../Atoms/Button/Button";
import {
  ANSWER_SCALE_MAX,
  ANSWER_SCALE_MIN,
  TEST_TIPS_ARR,
} from "../utils/constants";
import { Text } from "../Atoms/Typography/Text";
import { TestTip } from "../Molecules/TestTip";
import ScoreSimple from "../Molecules/ScoreSimple";
import { handleAnswerSubmit } from "../utils/functions";
import { ToAnswerCandidateProps } from "../utils/types/test";

const calculateScorePercentages = (rightScore: number) => {
  const leftScorePercentage =
    rightScore !== undefined && rightScore !== null
      ? 100 -
        Math.round((rightScore / (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN)) * 100)
      : 50;
  const rightScorePercentage = 100 - leftScorePercentage;
  return { leftScorePercentage, rightScorePercentage };
};

export const ToAnswerCandidate: React.FC<ToAnswerCandidateProps> = ({
  content,
  step,
  setStep,
  setScore,
  stepData,
  testId,
  setScoreId,
}) => {
  const SHOW_AFTER_MS = 10 * 1000; // sec * milisec
  const [testTip, setTestTip] = useState<string>(
    "Es gibt kein richtig oder falsch, antworte aus dem Bauch heraus."
  );
  const [errorOpen, setErrorOpen] = useState(false);
  const [isTipShown, setTipIsShown] = useState<boolean>(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>(
    setTimeout(() => {}, 0)
  );

  const randomizeTips = (arr: string[]) => {
    setTestTip(arr[Math.floor(Math.random() * arr.length)]);
  };

  const { leftScorePercentage, rightScorePercentage } =
    calculateScorePercentages(
      stepData.test_expressions[1].expression_values.score
    );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  // show tip after not using slider for a while
  useEffect(() => {
    clearTimeout(timer);
    setTipIsShown(false);
    setTimer(
      setTimeout(() => {
        setTipIsShown(true);
      }, SHOW_AFTER_MS)
    );
    randomizeTips(TEST_TIPS_ARR);
  }, [step]);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item xs={12}>
        <Text text={stepData.test_step_title} centered textClass="expression" />
      </Grid>
      <Grid container item justifyContent="center" spacing={{ xs: 3, sm: 8 }}>
        <Grid container item xs={12} alignItems="stretch">
          <Grid item xs={0} sm={0} />
          <Grid container item xs={5.5} sm={5} alignItems="stretch">
            <Expression
              content={content.expression1}
              score={stepData.test_expressions[0].expression_values.score}
            />
          </Grid>
          <Grid item xs={1} sm={2} />
          <Grid container item xs={5.5} sm={5} alignItems="stretch">
            <Expression
              content={content.expression2}
              score={stepData.test_expressions[1].expression_values.score}
            />
          </Grid>
          <Grid item xs={0} sm={0} />
        </Grid>
        <Grid
          item
          sx={{ display: { xs: "none", sm: "block" } }}
          sm={2}
          md={1}
        />
      </Grid>
      <Grid
        item
        container
        spacing={4}
        xs={12}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          sx={{ display: { xs: "none", sm: "block" } }}
          item
          sm={1.5}
          md={1.2}
        >
          <ScoreSimple scorePercentage={leftScorePercentage} />
        </Grid>
        <Grid item xs={11.5} sm={9} md={9.6}>
          <AnswerScale
            score={stepData.test_expressions[1].expression_values.score}
            setScore={setScore}
            step={step}
            setTipIsShown={setTipIsShown}
          />
        </Grid>
        <Grid
          sx={{ display: { xs: "none", sm: "block" } }}
          item
          sm={1.5}
          md={1.2}
        >
          <ScoreSimple scorePercentage={rightScorePercentage} />
        </Grid>
        <Fade in={isTipShown}>
          <Grid item xs={11.5} sm={9} md={9.6}>
            <TestTip text={testTip} />
          </Grid>
        </Fade>
      </Grid>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Huch! Etwas ist schiefgelaufen. Bitte versuche es nochmal
        </Alert>
      </Snackbar>
      <Grid item container xs={12} justifyContent="space-evenly">
        <Button
          className="back"
          disabled={false}
          secondary
          clickHandler={() => {
            setStep(step - 1);
          }}
        >
          Zurück
        </Button>
        <Button
          className="next"
          disabled={
            stepData.test_expressions[1].expression_values.score ===
              undefined ||
            stepData.test_expressions[1].expression_values.score === null
          }
          clickHandler={async () => {
            try {
              await handleAnswerSubmit(stepData, testId, setScoreId, step - 1);
              setStep(++step);
            } catch (e) {
              setErrorOpen(true);
            }
          }}
        >
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
};
