import { Grid, CircularProgress } from "@mui/material";
import { MouseEvent, useRef, useState } from "react";
import { TestCategoriesModel } from "../utils/types/test";
import ScoreGraph from "../Organisms/ScoreGraph";
import ValueDescriptionList from "../Organisms/ValueDescriptionList";
import { ANSWER_SCALE_MAX, ANSWER_SCALE_MIN } from "../utils/constants";
import { apiBaseUrl } from "../utils/environmentDependencies";
import { Button } from "../Atoms/Button/Button";
import { saveAs } from "file-saver";
import ReturnToJob from "../Molecules/ReturnToJob";

interface PersonalResultsProps {
  values?: TestCategoriesModel[];
  testId: number;
  key: string;
  isApplication: boolean;
  jobIdStr?: string;
  matchingScoreAverage?: number;
}

const PersonalResults: React.FC<PersonalResultsProps> = ({
  values,
  testId,
  key,
  isApplication,
  jobIdStr,
  matchingScoreAverage,
}) => {
  // Ref for container of result, for getting max width
  const scoreContainerRef = useRef<HTMLDivElement>(null);
  const [isDrawn, setIsDrawn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = function (e: MouseEvent) {
    setIsLoading(true);
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/test/${testId}/download?key=${key}&min_score=${ANSWER_SCALE_MIN}&max_score=${ANSWER_SCALE_MAX}`
    )
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "Recunited Testergebnisse.pdf");
        setIsLoading(false);
      });
  };

  return (
    <Grid container xs={12} spacing={5}>
      {jobIdStr && (
        <Grid item container xs={12} justifyContent="center">
          <ReturnToJob
            isApplication={isApplication}
            jobId={jobIdStr}
            matchingScoreAverage={matchingScoreAverage}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <div ref={scoreContainerRef} style={{ width: "100%" }}>
          {values && (
            <ScoreGraph
              setIsDrawn={setIsDrawn}
              valueCategories={values}
              maxWidth={(() => {
                let scoreContainer = scoreContainerRef.current;
                if (scoreContainer) {
                  return scoreContainer.offsetWidth;
                } else {
                  return 0;
                }
              })()}
            />
          )}
        </div>
      </Grid>
      <Grid container item xs={12}>
        <ValueDescriptionList values={values} />
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            clickHandler={handleDownload}
            className="submit"
            disabled={false}
            type="submit"
          >
            Persönliches Testergebnis herunterladen
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PersonalResults;
