import React, { MouseEventHandler } from "react";
import styles from "../../scss/modules/button.module.scss";
import clsx from "clsx";

interface ButtonProps {
  clickHandler?: MouseEventHandler<HTMLButtonElement> | undefined;
  className: string;
  disabled: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  secondary?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  clickHandler,
  className,
  disabled,
  children,
  secondary,
  type,
}) => {
  return (
    <div id="container" className={styles[className]}>
      <button
        disabled={disabled}
        onClick={clickHandler}
        className={clsx(
          disabled && styles.disabled,
          secondary && styles.secondary
        )}
        type={type}
      >
        {children}
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};
