import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Text } from "../Atoms/Typography/Text";
import TextFeedback from "../Molecules/TextFeedback";
import { apiBaseUrl } from "../utils/environmentDependencies";
import SmileyRating from "./SmileyRating";
import { Link } from "@mui/material";

const SMILEY_FEEDBACK_ID = 1;
const TEXT_FEEDBACK_ID = 1;

interface EndProps {
  testId: number;
  secret_key: string;
  isCandidate: boolean;
  jobId?: number;
  fromUrl?: string;
}

export const End: React.FC<EndProps> = ({
  testId,
  secret_key,
  isCandidate,
  jobId,
  fromUrl,
}) => {
  const [smileyFeedbackQuestion, setSmileyFeedbackQuestion] =
    useState<string>();
  const [textFeedbackQuestion, setTextFeedbackQuestion] = useState<string>();

  useEffect(() => {
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/feedback/score/question/${SMILEY_FEEDBACK_ID}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSmileyFeedbackQuestion(data.question);
      })
      .catch((err) => {});

    fetch(
      `${apiBaseUrl}/recunited/questionnaire/feedback/text/question/${TEXT_FEEDBACK_ID}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTextFeedbackQuestion(data.question);
      })
      .catch((err) => {});
    fetch(`${apiBaseUrl}/recunited/questionnaire/test/${testId}`, {
      method: "PUT",
      body: JSON.stringify({ is_done: true }),
    });
  }, []);

  return (
    <>
      <Grid item container justifyContent="center" alignContent="center">
        <>
          {isCandidate ? (
            <Grid item xs={12}>
              <Text textClass={"subHeader"} text={"Geschafft!"} />
              <Text
                text="Wir haben deinen Test ausgewertet und dir einen Link mit deiner persönlichen Auswertung per E-Mail geschickt."
                textClass="small"
              />
              <Text
                textClass="small"
                text="Schau auch im Spam nach, falls du keine Mail im Posteingang findest. Alternativ schreib uns gerne eine Mail an hallo@recunited.com :)."
                color="light"
              />
              {fromUrl && (
                <Link href={fromUrl}>
                  <Text
                    textClass="small"
                    text="Hier geht es zurück zu dem Jobangebot"
                  />
                </Link>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Text textClass="subHeader" text="Schon geschafft!" />
              <Text
                text="Vielen Dank für deine Unterstützung bei der Suche nach passenden neuen Kolleg:innen für dich und dein Team!"
                textClass="small"
              />
              <Text
                textClass="small"
                text="Falls dich dein eigenes Ergebnis auch interessiert, kannst du hier gerne den Test für dein eigenes Ergebnis machen:"
                color="light"
              />
              <Link href="https://test.recunited.com" target="_blank">
                <Text component="span" textClass="form" text="-->" />
              </Link>
              <Text
                text={
                  "Wir drücken dir die Daumen für tolle matches und passende neue Teammembers :)"
                }
              />
            </Grid>
          )}
          <Grid>
            {smileyFeedbackQuestion && (
              <Grid item xs={12}>
                <SmileyRating
                  testId={testId}
                  secret_key={secret_key}
                  id={SMILEY_FEEDBACK_ID}
                  questionText={smileyFeedbackQuestion}
                />
              </Grid>
            )}
            {textFeedbackQuestion && (
              <Grid item xs={12}>
                <TextFeedback
                  testId={testId}
                  secret_key={secret_key}
                  id={TEXT_FEEDBACK_ID}
                  questionText={textFeedbackQuestion}
                />
              </Grid>
            )}
          </Grid>
        </>
      </Grid>
    </>
  );
};
