import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface LogoProps {
  title: string;
  image: string;
}

const Logo: React.FC<LogoProps> = (props) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { title, image } = props;

  return <img alt={title} src={image} width={isXs ? "150" : "200"} />;
};

export default Logo;
