import { Button, CardActions, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
// import { useNavigate } from "react-router-dom";
import { MatchingScore } from "../Atoms/MatchingScore";
import { Text } from "../Atoms/Typography/Text";

export interface MatchingInfoProps {
  fit: string;
  text: string;
  matchingScore: number;
  id?: string;
}

export const CategoryCard: React.FC<MatchingInfoProps> = ({
  fit,
  text,
  matchingScore,
  id,
}) => {
  //   let history = useNavigate();

  // fetch id and put in url

  //   const handleClick = () => {
  //     history.push(`/jobdetails/${id}/matchingresults/${fit}`);
  //   };

  return (
    <div>
      <Card variant="outlined" sx={{ minHeight: "15rem" }}>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          flexDirection="row"
        >
          <Grid item xs={9}>
            <CardContent sx={{ height: "10rem" }}>
              <Text text={fit} bold />
              <Text text={text} />
            </CardContent>
          </Grid>
          <Grid
            item
            container
            xs={3}
            justifyContent="center"
            alignItems="center"
          >
            <MatchingScore matchingScore={matchingScore} />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent={"end"}>
          {/* <CardActions>
            <Button size="small" onClick={() => handleClick()}>
              Mehr lesen
            </Button>
          </CardActions> */}
        </Grid>
      </Card>
    </div>
  );
};
