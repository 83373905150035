import { Alert, Container, Grid, Snackbar, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppHeader from "../Atoms/AppHeader";
import { Text } from "../Atoms/Typography/Text";
import ReturnToJob from "../Molecules/ReturnToJob";
import TextFeedback from "../Molecules/TextFeedback";
import MatchingResults from "../Organisms/MatchingResults";
import PersonalResults from "../Organisms/PersonalResults";
import SmileyRating from "../Organisms/SmileyRating";
import SocialMediaOverview from "../Organisms/SocialMediaOverview";
import {
  ANSWER_SCALE_MAX,
  ANSWER_SCALE_MIN,
  PAGE_TITLE,
} from "../utils/constants";
import { apiBaseUrl } from "../utils/environmentDependencies";
import { fireGAPageView } from "../utils/googleAnalytics";
import { MatchingScore } from "../utils/types/matching";
import { TestCategoriesModel, TestResultsModel } from "../utils/types/test";

const SMILEY_FEEDBACK_ID = 2;
const TEXT_FEEDBACK_ID = 2;

export const calculateAverageMatchingScore = (
  matchingScores: MatchingScore[]
) => {
  let averageScore: number = 0;

  matchingScores.forEach((categoryScore) => {
    averageScore = averageScore + categoryScore.matching_score;
  });

  return Math.round((averageScore / matchingScores.length) * 100);
};

const TestResults: React.FC = () => {
  const [searchParams] = useSearchParams();
  let strTestId = searchParams.get("test_id");
  let strIsEmbedded = searchParams.get("is_embedded");
  let strKey = searchParams.get("key");
  let testId = 0;
  if (strTestId) {
    testId = parseInt(strTestId, 10);
  }
  let isEmbedded = false;
  if (strIsEmbedded === "true") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isEmbedded = true;
  }
  let key = strKey ? strKey : "";
  let jobIdStr = searchParams.get("job_id") ?? undefined;
  let jobId = jobIdStr ?? undefined;

  const isApplication = Boolean(searchParams.get("application"));

  // State for potential errors
  const [errorOpen, setErrorOpen] = useState(false);
  const [matchingData, setMatchingData] = useState<MatchingScore[]>();
  const [jobTitle, setJobTitle] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);

  // State for retrieved values
  const [values, setValues] = useState<TestCategoriesModel[]>();

  // States for feedback questions
  const [smileyFeedbackQuestion, setSmileyFeedbackQuestion] =
    useState<string>();
  const [textFeedbackQuestion, setTextFeedbackQuestion] = useState<string>();

  // Placeholder state for forced rerender of canvas on resize
  const [, setWidth] = useState<number>();

  // handle for potential error messages
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  useEffect(() => {
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/feedback/score/question/${SMILEY_FEEDBACK_ID}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSmileyFeedbackQuestion(data.question);
      })
      .catch((err) => {});

    fetch(
      `${apiBaseUrl}/recunited/questionnaire/feedback/text/question/${TEXT_FEEDBACK_ID}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTextFeedbackQuestion(data.question);
      })
      .catch((err) => {});

    document.title = PAGE_TITLE + " | Ergebnis";
    fireGAPageView();
    // Resize event listener for forced rerender of canvas
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    // get result
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/test/get_score/${testId}?key=${key}&max_score=${ANSWER_SCALE_MAX}&min_score=${ANSWER_SCALE_MIN}`
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setErrorOpen(true);
        }
      })
      .then((data: TestResultsModel) => {
        setValues(data.value_categories);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (jobId) {
      fetch(
        `${apiBaseUrl}/recunited/questionnaire/matching?test_id=${testId}&key=${key}&job_id=${jobId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setMatchingData(data.matching_scores);
          setJobTitle(data.job_title);
        });
    }
  }, []);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <Grid
        container
        item
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </Grid>
    );
  }

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <AppHeader />
      <Container maxWidth="md">
        <Grid item container spacing={5} xs={12}>
          <Grid item xs={12}>
            <Text
              text="Hier sind deine Testergebnisse!"
              textClass="subHeader"
            />
          </Grid>
          {jobId ? (
            <>
              <Grid item xs={12}>
                <Tabs
                  value={activeTab}
                  onChange={(e, val) => {
                    setActiveTab(val);
                  }}
                  variant="fullWidth"
                  style={{ borderBottom: "1px solid grey" }}
                >
                  <Tab
                    style={{ color: "#6bb3c7" }}
                    label="Persönliche Ergebnisse"
                    {...tabProps(0)}
                  />
                  <Tab
                    style={{ color: "#6bb3c7" }}
                    label="Gemeinsamkeiten zum Team"
                    {...tabProps(1)}
                  />
                </Tabs>
              </Grid>
              <TabPanel value={activeTab} index={0}>
                <PersonalResults
                  values={values}
                  testId={testId}
                  key={key}
                  isApplication={isApplication}
                  jobIdStr={jobIdStr}
                  matchingScoreAverage={calculateAverageMatchingScore(
                    matchingData || []
                  )}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <MatchingResults
                  jobTitle={jobTitle}
                  jobIdStr={jobIdStr}
                  matchingData={matchingData}
                  isApplication={isApplication}
                  matchingScoreAverage={calculateAverageMatchingScore(
                    matchingData || []
                  )}
                />
              </TabPanel>
            </>
          ) : (
            <Grid item xs={12}>
              <PersonalResults
                values={values}
                testId={testId}
                key={key}
                isApplication={isApplication}
              />
            </Grid>
          )}
          <Grid container item xs={12}>
            <SocialMediaOverview />
          </Grid>
          {smileyFeedbackQuestion && (
            <Grid item xs={12}>
              <SmileyRating
                id={SMILEY_FEEDBACK_ID}
                questionText={smileyFeedbackQuestion}
                secret_key={key}
                testId={testId}
              />
            </Grid>
          )}
          {jobIdStr && (
            <Grid item container xs={12} justifyContent="center">
              <ReturnToJob
                isApplication={isApplication}
                jobId={jobIdStr}
                matchingScoreAverage={calculateAverageMatchingScore(
                  matchingData || []
                )}
              />
            </Grid>
          )}
          {textFeedbackQuestion && (
            <Grid item xs={12}>
              <TextFeedback
                testId={testId}
                secret_key={key}
                id={TEXT_FEEDBACK_ID}
                questionText={textFeedbackQuestion}
              />
            </Grid>
          )}
        </Grid>
        <Snackbar
          open={errorOpen}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Huch! Etwas ist schiefgelaufen. Bitte versuche nochmal, die Seite zu
            laden, oder kontaktiere uns wenn das Problem bleibt
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default TestResults;
