import { Grid } from "@mui/material";
import { Matching } from "../Organisms/Matching";
import ReturnToJob from "../Molecules/ReturnToJob";
import { MatchingResultsProps, MatchingScore } from "../utils/types/matching";

const MatchingResults: React.FC<MatchingResultsProps> = ({
  jobTitle,
  matchingData,
  jobIdStr,
  isApplication,
  matchingScoreAverage,
}) => {
  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="center">
        <ReturnToJob
          isApplication={isApplication}
          jobId={jobIdStr}
          matchingScoreAverage={matchingScoreAverage}
        />
      </Grid>
      ,
      <Grid item container xs={12} justifyContent="center">
        <Matching
          jobTitle={jobTitle}
          matchingScores={matchingData}
          id={jobIdStr}
        />
      </Grid>
      ,
    </Grid>
  );
};

export default MatchingResults;
