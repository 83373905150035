import { CheckboxWithLabel, RadioGroup, TextField } from "formik-mui";
import {
  Grid,
  CircularProgress,
  MenuItem,
  Container,
  Radio,
  FormControlLabel,
  Link,
  FormHelperText,
  Alert,
  Snackbar,
  Slide,
} from "@mui/material";
import { ConnectedFocusError } from "focus-formik-error";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { apiBaseUrl } from "../utils/environmentDependencies";
import {
  fireGAEvent,
  GA_CATEGORY_ADVANCE,
  GA_CATEGORY_FORM,
} from "../utils/googleAnalytics";
import { Text } from "../Atoms/Typography/Text";
import { Button } from "../Atoms/Button/Button";
import styles from "../scss/modules/finalizeForm.module.scss";
import { INDUSTRIES } from "../utils/constants";

// Props declaration for the component
interface FinalizeFormProps {
  step: number;
  setStep: (step: number) => void;
  testId: number;
  secretKey: string;
  jobId?: number;
  isApplication?: boolean;
}

// Declaration of form values
const AVAILABLE_EXPERIENCE = ["1-3", "3-5", "5-7", "7-10", "10+"] as const;
const AVAILABLE_SEX = ["Herr", "Frau", "Divers"] as const;

interface FormSubmissionValues {
  email: string;
  name: string;
  surname: string;
  industry: string;
  sex?: typeof AVAILABLE_SEX[number];
  job?: string;
  currentlyEmployed?: "true" | "false";
  consulting?: boolean;
  experience?: typeof AVAILABLE_EXPERIENCE[number];
  dsgvo: boolean;
}

// Schema definition
const useSchema = (jobId?: number) => {
  return yup.object({
    email: yup
      .string()
      .required("Bitte Email angeben")
      .email("Email ist nicht gültig"),
    name: yup.string().required("Bitte Vornamen angeben"),
    surname: yup.string().required("Bitte Nachname angeben"),
    sex: yup.mixed().oneOf([undefined, ...AVAILABLE_SEX]),
    job: yup.string(),
    currentlyEmployed: yup.mixed().oneOf([undefined, "true", "false"]),
    consulting: yup.bool(),
    experience: yup.mixed().oneOf([undefined, ...AVAILABLE_EXPERIENCE]),
    dsgvo: yup.bool().oneOf([true], "Bitte der DSGVO zustimmen"),
    industry: yup
      .string()
      .oneOf(INDUSTRIES)
      .required("Bitte wähle dein Tätigkeitsfeld aus"),
  });
};

const FinalizeForm: React.FC<FinalizeFormProps> = ({
  testId,
  setStep,
  step,
  jobId,
  secretKey,
  isApplication,
}) => {
  const schema = useSchema(jobId);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState(false);

  // handle for potential error messages
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  // Initial values of the form
  const initialValues: FormSubmissionValues = {
    email: "",
    name: "",
    surname: "",
    industry: "",
    dsgvo: false,
    consulting: false,
  };

  // Submit function
  const handleSubmit = (values: FormSubmissionValues) => {
    fireGAEvent(GA_CATEGORY_FORM, GA_CATEGORY_FORM.actions.submitForm);
    setIsLoading(true);
    let requestBody = {
      user_email: values.email,
      user_name: values.name,
      user_surname: values.surname,
      consulting: values.consulting,
      industry: values.industry,
      ...(values.job && { user_job: values.job }),
      ...(values.experience && { user_experience: values.experience }),
      ...(values.currentlyEmployed && {
        user_currently_employed: values.currentlyEmployed === "true",
      }),
      ...(values.sex && { user_sex: values.sex }),
    };
    fetch(`${apiBaseUrl}/recunited/questionnaire/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        let userId = data.id;
        fetch(
          `${apiBaseUrl}/recunited/questionnaire/test/${testId}/user/${userId}`,
          {
            method: "PUT",
            body: JSON.stringify({ job_id: jobId, isApplication }),
          }
        )
          .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
              setStep(++step);
            } else {
              setErrorOpen(true);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setErrorOpen(true);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setErrorOpen(true);
        setIsLoading(false);
      });
  };

  return (
    <Container maxWidth="sm">
      <Slide direction="left" appear in timeout={1000}>
        <Grid container item xs={12} spacing={3}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={12}>
              <Text text={"Geschafft!"} textClass={"subHeader"} />
              <Text
                text={
                  jobId
                    ? "Noch ein paar Details, und dann erhältst du eine Email mit einer Auswertung zu deinen persönlichen Werten und deinem Matching Ergebnis! Dann kannst du entscheiden, ob du dich bewerben möchtest"
                    : "Noch ein paar Details, und dann schicken wir dir deine Testergebnisse direkt per Email."
                }
                textClass={"small"}
              />
            </Grid>
            <Grid container item xs={12}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
              >
                {({ errors, touched }) => (
                  <Form noValidate>
                    <ConnectedFocusError />
                    <Grid container item xs={12} gap={5}>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="center"
                        className={styles.container}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          className={styles.formContainer}
                        >
                          <Field
                            label="Anrede"
                            name="sex"
                            component={TextField}
                            select
                            variant="filled"
                            size="small"
                            fullWidth
                            children={AVAILABLE_SEX.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s}
                              </MenuItem>
                            ))}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4.5}
                          className={styles.formContainer}
                        >
                          <Field
                            required
                            label="Vorname"
                            name="name"
                            variant="filled"
                            size="small"
                            component={TextField}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4.5}
                          className={styles.formContainer}
                        >
                          <Field
                            required
                            label="Nachname"
                            name="surname"
                            variant="filled"
                            size="small"
                            component={TextField}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} className={styles.formContainer}>
                          <Field
                            required
                            label="Email"
                            name="email"
                            variant="filled"
                            size="small"
                            component={TextField}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="center"
                        className={styles.container}
                      >
                        <Grid item xs={12} className={styles.formContainer}>
                          <Field
                            required
                            label="Tätigkeitsfeld"
                            name="industry"
                            component={TextField}
                            variant="filled"
                            size="small"
                            fullWidth
                            select
                            children={INDUSTRIES.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s}
                              </MenuItem>
                            ))}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7.5}
                          className={styles.formContainer}
                        >
                          <Field
                            label="Aktueller Berufstitel"
                            name="job"
                            variant="filled"
                            size="small"
                            component={TextField}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4.5}
                          className={styles.formContainer}
                        >
                          <Field
                            label="Berufserfahrung"
                            name="experience"
                            component={TextField}
                            variant="filled"
                            size="small"
                            fullWidth
                            select
                            children={AVAILABLE_EXPERIENCE.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s + " Jahre"}
                              </MenuItem>
                            ))}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{ xs: { spacing: 0 }, sm: { spacing: 2 } }}
                          alignItems="center"
                          className={styles.formContainer}
                        >
                          <Grid item xs={12} sm={8} style={{ paddingLeft: 12 }}>
                            <Text
                              text={"Aktuell berufstätig?"}
                              textClass="form"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} style={{ paddingLeft: 12 }}>
                            <Field
                              name="currentlyEmployed"
                              component={RadioGroup}
                              row
                              children={[
                                <FormControlLabel
                                  key="fc1"
                                  label="Ja"
                                  value={"true"} // terrible, but it looks like the library can't handle booleans here
                                  control={<Radio size="small" />}
                                />,
                                <FormControlLabel
                                  key="fc2"
                                  label="Nein"
                                  value={"false"}
                                  control={<Radio size="small" />}
                                />,
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="center"
                        className={styles.container}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          className={styles.formContainer}
                        >
                          <Field
                            Label={{
                              label: (
                                <>
                                  <Text
                                    component="span"
                                    textClass="form"
                                    text="Ich stimme der "
                                  />
                                  <Link
                                    href="https://recunited.com/dataprotection"
                                    target="_blank"
                                  >
                                    <Text
                                      component="span"
                                      textClass="form"
                                      text="Datenschutzerklärung"
                                    />
                                  </Link>
                                  <Text
                                    component="span"
                                    textClass="form"
                                    text=" zu.*"
                                  />
                                </>
                              ),
                            }}
                            required
                            name="dsgvo"
                            component={CheckboxWithLabel}
                          />
                          <FormHelperText
                            error={Boolean(errors.dsgvo && touched.dsgvo)}
                          >
                            {errors.dsgvo && touched.dsgvo ? errors.dsgvo : ""}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} className={styles.formContainer}>
                          <Field
                            Label={{
                              label: (
                                <Text
                                  component="span"
                                  textClass="form"
                                  text="Ja, ich habe Interesse an einem kostenfreien,
                                persönlichen Beratungsgespräch mit Daniela zu
                                meinen persönlichen Werten."
                                />
                              ),
                            }}
                            required
                            name="consulting"
                            component={CheckboxWithLabel}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        justifyContent="center"
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent="space-evenly"
                        >
                          {isLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <>
                              <Button
                                className="back"
                                type="button"
                                disabled={false}
                                secondary
                                clickHandler={() => {
                                  fireGAEvent(
                                    GA_CATEGORY_ADVANCE,
                                    GA_CATEGORY_ADVANCE.actions.goBack
                                  );
                                  setStep(step - 1);
                                }}
                              >
                                Zurück
                              </Button>
                              <Button
                                type="submit"
                                className="submit"
                                disabled={isLoading}
                              >
                                Absenden
                              </Button>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Huch! Etwas ist schiefgelaufen. Bitte versuche es nochmal
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FinalizeForm;
