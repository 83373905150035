import { Container, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CanvasContainer from "../Atoms/Diamond/CanvasContainer";
import { Diamond } from "../Atoms/Diamond/Diamond";
import { Text } from "../Atoms/Typography/Text";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("sm")]: {
      header: {
        textAlign: "center",
        color: "#504E63",
        fontWeight: 600,
        fontSize: 22,
      },
      container: {
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem",
      },
    },
    [theme.breakpoints.up("sm")]: {
      header: {
        textAlign: "center",
        color: "#504E63",
        fontWeight: 600,
      },
      container: {
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "flex-end",
        alignContent: "space-evenly",
        padding: "2rem",
      },
    },
  })
);

const NotFound: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <Grid className={classes.container} container>
        <Grid item xs={12}>
          <CanvasContainer fov={7}>
            <Diamond type={"wire"} color={"#60a8b9"} />
          </CanvasContainer>
          <Text
            textClass="subHeader"
            text="Sorry! Sieht so aus als ob die angeforderte Seite nicht mehr existiert."
            centered
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
