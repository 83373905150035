import { Grid, Link, useMediaQuery, useTheme } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Text } from "../Atoms/Typography/Text";
import styles from "../scss/modules/socialMediaOverview.module.scss";

const SocialMediaOverview: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={12}>
        <Text
          textClass="subHeader"
          text="Du willst noch mehr über dich erfahren?"
        />
        <Text
          textClass="small"
          component="span"
          text="Du willst ein kostenlosen,
          persönliches Beratungsgespräch zu deinen Werten? Du willst einen Job
          und ein Team passend zu deinen Werten finden? "
        />
        <Text
          textClass="small"
          component="span"
          text="Folge uns bei Instagram & Facebook, lern mehr über deine Werte und
          verpass nicht, wenn wir die erste wertebasierte Jobplattform launchen"
          color="light"
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item container justifyContent="space-between" xs={12}>
        <Grid item className={styles.iconContainer}>
          <Link
            underline="none"
            href="https://twitter.com/Recunited1"
            target="_blank"
          >
            <TwitterIcon className={styles.icon} />
            {!isSmallScreen && <Text textClass="caption" text="Twitter" />}
          </Link>
        </Grid>
        <Grid item className={styles.iconContainer}>
          <Link
            underline="none"
            href="https://www.facebook.com/Recunited-112717310861818"
            target="_blank"
          >
            <FacebookIcon className={styles.icon} />
            {!isSmallScreen && <Text textClass="caption" text="Facebook" />}
          </Link>
        </Grid>
        <Grid item className={styles.iconContainer}>
          <Link
            underline="none"
            href="https://www.instagram.com/recunited/?hl=de"
            target="_blank"
          >
            <InstagramIcon className={styles.icon} />
            {!isSmallScreen && <Text textClass="caption" text="Instagram" />}
          </Link>
        </Grid>
        <Grid item className={styles.iconContainer}>
          <Link
            underline="none"
            href="https://www.youtube.com/channel/UC5fITHKM4CkjNh6IM2Q6LHg"
            target="_blank"
          >
            <YouTubeIcon className={styles.icon} />
            {!isSmallScreen && <Text textClass="caption" text="Youtube" />}
          </Link>
        </Grid>
        <Grid item className={styles.iconContainer}>
          <Link
            underline="none"
            href="https://www.linkedin.com/company/recunited"
            target="_blank"
          >
            <LinkedInIcon className={styles.icon} />
            {!isSmallScreen && <Text textClass="caption" text="LinkedIn" />}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SocialMediaOverview;
