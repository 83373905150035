import { Avatar, Typography } from "@mui/material";

interface MatchingScoreProps {
  matchingScore: number;
}

const setColor = (matchingScore: number) => {
  // not a match
  if (matchingScore < 50) {
    return "#E16E63";
  }
  // match
  return "#227671";
};

export const MatchingScore: React.FC<MatchingScoreProps> = ({
  matchingScore,
}) => {
  let roundedScore = Math.round(matchingScore * 100);
  return (
    <>
      <Avatar
        sx={{
          width: 60,
          height: 60,
          backgroundColor: setColor(roundedScore),
          marginTop: "2rem",
        }}
      >
        <Typography color="white" variant="subtitle1">
          {roundedScore}%
        </Typography>
      </Avatar>
    </>
  );
};
