import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import Questionnaire from "./UtilComponents/Questionnaire";
import "./scss/modules/app.module.scss";
import React, { useEffect } from "react";
import theme from "./utils/theme";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import TestResults from "./Templates/TestResults";
import GettingTestReady from "./Organisms/GettingTestReady";
import NotFound from "./Templates/NotFound";
import ReactPixel from "react-facebook-pixel";
import "./scss/mui.scss";

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactPixel.init("121617349974896");
      ReactPixel.pageView();
    }
  }, []);
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<GettingTestReady isCandidateTest={true}/>} />
              <Route path="/test" element={<Questionnaire />} />
              <Route path="/results" element={<TestResults />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/company_test/:teamId" element={<GettingTestReady isCandidateTest={false}/>} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
