import { CircularProgress, Grid } from "@mui/material";
import { ConnectedFocusError } from "focus-formik-error";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";
import * as yup from "yup";
import { Button } from "../Atoms/Button/Button";
import { Text } from "../Atoms/Typography/Text";
import { apiBaseUrl } from "../utils/environmentDependencies";

interface FeedbackFormValues {
  text: string;
}

interface TextFeedbackProps {
  id: number;
  questionText: string;
  testId: number;
  secret_key: string;
}

const schema = yup.object({
  text: yup.string().required("Bitte schreibe uns Feedback"),
});

const TextFeedback: React.FC<TextFeedbackProps> = ({
  id,
  questionText,
  testId,
  secret_key,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFeedbackGiven, setIsFeedbackGiven] = useState<boolean>(false);
  const handleSubmit = (values: FeedbackFormValues) => {
    let body = { text: values.text, test_id: testId, key: secret_key };
    setIsLoading(true);
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/feedback/text/question/${id}/answer`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }
    )
      .then(() => {
        setIsLoading(false);
        setIsFeedbackGiven(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Text textClass="subHeader" text={questionText} />
      </Grid>
      {isFeedbackGiven ? (
        <Grid item xs={12}>
          <Text textClass="small" text="Danke für dein Feedback" />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Formik
            initialValues={{ text: "" }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            <Form noValidate>
              <ConnectedFocusError />
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Field
                    label="Dein Feedback"
                    name="text"
                    component={TextField}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
                <Grid item container xs={12}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button
                      type="submit"
                      className="submit"
                      disabled={isLoading}
                    >
                      Absenden
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};

export default TextFeedback;
