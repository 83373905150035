import React, { useEffect, useState } from "react";
import { Text } from "../Atoms/Typography/Text";

interface TestTipProps {
  text: string;
}

export const TestTip: React.FC<TestTipProps> = ({ text }) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const MILISECONDS = 1 * 1000; // sec * milisec

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, MILISECONDS);
  }, [MILISECONDS]);

  return <Text text={text} centered color="light" textClass="expression" />;
};
