import { Grid } from "@mui/material";
import React from "react";
import { RadarGraph } from "../Atoms/RadarGraph";
import { Text } from "../Atoms/Typography/Text";
import { CategoryCard } from "../Molecules/CategoryCard";
import { calculateAverageMatchingScore } from "../Templates/TestResults";
import { MatchingProps } from "../utils/types/matching";

export const Matching: React.FC<MatchingProps> = ({
  matchingScores,
  id,
  jobTitle,
}) => {
  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <Text
              textClass="small"
              text={`Dein Matching-Ergebnis zum Team der Stelle ${jobTitle}`}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            xs={12}
          >
            {matchingScores && (
              <Text
                textClass={"subHeader"}
                color="default"
                text={`Matching insgesamt: ${calculateAverageMatchingScore(
                  matchingScores
                )}%`}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <RadarGraph matchingScores={matchingScores} />
          </Grid>
          <Grid xs={12} />
          <Grid item container xs={12}>
            <Grid item container xs={12} spacing={1}>
              {matchingScores?.map((category, index) => {
                return (
                  <Grid item xs={12} md={6}>
                    <CategoryCard
                      fit={category.fit}
                      text={category.description}
                      matchingScore={category.matching_score}
                      id={id}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
