import { useEffect, useRef } from "react";
import { TestCategoriesModel, TestValuesModel } from "../utils/types/test";
import { ANSWER_SCALE_MAX, ANSWER_SCALE_MIN } from "../utils/constants";

interface ScoreGraphProps {
  maxWidth: number;
  valueCategories: TestCategoriesModel[];
  setIsDrawn: (isDrawn: boolean) => void;
}

interface Point {
  x: number;
  y: number;
}

const COLOR_CATEGORY_TEXT_PALETTE = [
  "#F88379",
  "#df766d",
  "#66b2b2",
  "#528e8e",
];
const COLOR_CATEGORY_FILL_PALETTE = [
  "#F88379",
  "#df766d",
  "#66b2b2",
  "#528e8e",
];
const COLOR_INDICATOR_LINES = "#000000";
const COLOR_VALUE_LINES = "#000000";
const COLOR_VALUE_TEXT = "#FFFFFF";

const ScoreGraph: React.FC<ScoreGraphProps> = ({
  maxWidth,
  valueCategories,
  setIsDrawn,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      context.clearRect(0, 0, maxWidth, maxWidth);
      // Initialize variables. Change those for minor adjustments (spacing, size, etc.)
      var numberOfSides = 10,
        lineStrength = 1,
        valuesLineStrength = 2,
        size = maxWidth / 2 - lineStrength * 2 - maxWidth * 0.2,
        categoryTextOffset = 1.5,
        categoryTextSize = "30px",
        valueTextSize = "15px",
        categoryTextYOffset = 30,
        valueTextOffset = 14,
        indicatorRadius = 5,
        xCenter = maxWidth / 2,
        yCenter = maxWidth / 2;

      // Size dependencies
      if (maxWidth < 700) {
        categoryTextSize = "18px";
        valueTextSize = "9px";
        indicatorRadius = 4;
        valuesLineStrength = 1.5;
      }
      if (maxWidth < 400) {
        size = maxWidth / 2.2 - lineStrength * 2 - maxWidth * 0.2;
        lineStrength = 0.5;
        categoryTextSize = "12px";
        valueTextSize = "9px";
        indicatorRadius = 2;
        valuesLineStrength = 1;
        valueTextOffset = 6;
        size = size * 0.75;
      }

      /**
       * Desired order of categories and their values
       * 		 --------
       * 	  /		     \
       *   /			    \
       * 	 |			    |
       * 	 |			    X
       * 	 \		      /
       * 		\________/
       * The general order of how it will be displayed is:
       * Start at 'X' (eastern corner with closest southern starting point) and
       * go counter clockwise (just how a circle is being build with trigonometrical functions)
       */

      var desiredOrder: { [key: string]: string[] } = {
        "Ich-Orientierung": ["Macht", "Leistung"],
        "Offenheit für Veränderungen": [
          "Vergnügen",
          "Stimulation",
          "Selbstbestimmung",
        ],
        "Wir-Orientierung": ["Universalismus", "Güte"],
        Bewahrung: ["Anpassung", "Tradition", "Sicherheit"],
      };

      // Category and Value-only array in its respective order like shown above
      var sortedCategories: TestCategoriesModel[] = [];
      var sortedValue: TestValuesModel[] = [];
      for (let categoryItem of Object.keys(desiredOrder)) {
        let category = valueCategories.find(
          (category) => category.category_name === categoryItem
        );
        if (category) {
          sortedCategories = sortedCategories.concat(category);
          for (let valueItem of desiredOrder[category.category_name]) {
            let value = category.values.find(
              (value) => value.nature_of_value === valueItem
            );
            if (value) sortedValue = sortedValue.concat(value);
          }
        }
      }

      // Calculate points for value corners
      var valueCorners: Point[] = Array.from(Array(numberOfSides).keys()).map(
        (v) => {
          return {
            x:
              xCenter +
              size * Math.cos(((v + 0.5) * 2 * Math.PI) / numberOfSides),
            y:
              yCenter +
              size * Math.sin(((v + 0.5) * 2 * Math.PI) / numberOfSides),
          };
        }
      );

      // Calculate concrete value coordinates
      var valueScoreCoords: Point[] = sortedValue.map((value, i) => {
        return {
          x:
            xCenter +
            ((size * value.score) / (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN)) *
              Math.cos(((i + 0.5) * 2 * Math.PI) / numberOfSides),
          y:
            yCenter +
            ((size * value.score) / (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN)) *
              Math.sin(((i + 0.5) * 2 * Math.PI) / numberOfSides),
        };
      });

      // Calculate size offset for the center of the edges
      var x1 = size * Math.cos((2 * Math.PI) / numberOfSides);
      var x15 = size * Math.cos((3 * Math.PI) / numberOfSides);
      var x2 = size * Math.cos((4 * Math.PI) / numberOfSides);
      var y1 = size * Math.sin((2 * Math.PI) / numberOfSides);
      var y15 = size * Math.sin((3 * Math.PI) / numberOfSides);
      var y2 = size * Math.sin((4 * Math.PI) / numberOfSides);
      var xCut = (x1 + x2) / 2;
      var yCut = (y1 + y2) / 2;
      var categoryTriangleOffset = Math.sqrt(
        Math.pow(x15 - xCut, 2) + Math.pow(y15 - yCut, 2)
      );

      // Calculate points for filling color BEFORE respective value
      var inBetweenPoints: Point[] = Array.from(
        Array(numberOfSides).keys()
      ).map((v) => {
        return {
          x:
            xCenter +
            (size - categoryTriangleOffset) *
              Math.cos((v * 2 * Math.PI) / numberOfSides),
          y:
            yCenter +
            (size - categoryTriangleOffset) *
              Math.sin((v * 2 * Math.PI) / numberOfSides),
        };
      });

      /**
       * DRAW CATEGORIES
       */

      let valueCount = 0;
      // Loop through all categories
      for (
        let categoryIndex = 0;
        categoryIndex < sortedCategories.length;
        categoryIndex++
      ) {
        // For each category, draw a polygon containing all its value parts
        context.beginPath();
        context.moveTo(xCenter, yCenter);
        context.lineTo(
          inBetweenPoints[valueCount].x,
          inBetweenPoints[valueCount].y
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (let _ of sortedCategories[categoryIndex].values) {
          context.lineTo(
            valueCorners[valueCount].x,
            valueCorners[valueCount].y
          );
          valueCount++;
        }
        // In case we reached the end, draw the line back to the beginning
        context.lineTo(
          valueCount === numberOfSides
            ? inBetweenPoints[0].x
            : inBetweenPoints[valueCount].x,
          valueCount === numberOfSides
            ? inBetweenPoints[0].y
            : inBetweenPoints[valueCount].y
        );
        // Move back to center and fill polygon
        context.lineTo(xCenter, yCenter);
        context.strokeStyle = COLOR_CATEGORY_FILL_PALETTE[categoryIndex];
        context.fillStyle = COLOR_CATEGORY_FILL_PALETTE[categoryIndex];
        context.lineWidth = lineStrength;
        context.fill();
        context.stroke();
      }

      /**
       * DRAW INDICATORS
       */

      // Outer indicator lines
      context.beginPath();
      context.moveTo(valueCorners[0].x, valueCorners[0].y);
      for (let i = 1; i < numberOfSides; i++) {
        context.lineTo(valueCorners[i].x, valueCorners[i].y);
      }
      context.lineTo(valueCorners[0].x, valueCorners[0].y);
      context.strokeStyle = COLOR_INDICATOR_LINES;
      context.lineWidth = lineStrength;
      context.stroke();

      // Inner indicator lines
      var perStepRadiusIncrease =
        size / (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN + 1);
      var normalsToValueCorners: Point[] = valueCorners.map((corner) => {
        return {
          x: (corner.x - xCenter) / size,
          y: (corner.y - yCenter) / size,
        };
      });
      for (
        let scoreIndex = 1;
        scoreIndex < ANSWER_SCALE_MAX - ANSWER_SCALE_MIN + 1;
        scoreIndex++
      ) {
        context.beginPath();
        context.moveTo(
          normalsToValueCorners[0].x * perStepRadiusIncrease * scoreIndex +
            xCenter,
          normalsToValueCorners[0].y * perStepRadiusIncrease * scoreIndex +
            yCenter
        );

        for (
          let normalIndex = 1;
          normalIndex < normalsToValueCorners.length;
          normalIndex++
        ) {
          context.lineTo(
            normalsToValueCorners[normalIndex].x *
              perStepRadiusIncrease *
              scoreIndex +
              xCenter,
            normalsToValueCorners[normalIndex].y *
              perStepRadiusIncrease *
              scoreIndex +
              yCenter
          );
        }
        context.lineTo(
          normalsToValueCorners[0].x * perStepRadiusIncrease * scoreIndex +
            xCenter,
          normalsToValueCorners[0].y * perStepRadiusIncrease * scoreIndex +
            yCenter
        );
        context.strokeStyle = COLOR_INDICATOR_LINES;
        context.lineWidth = lineStrength;
        context.stroke();
      }

      /**
       * DRAW VALUES
       */

      context.beginPath();
      // Start at position of first value
      context.moveTo(valueScoreCoords[0].x, valueScoreCoords[0].y);
      // Loop through value score coordinates array and connect
      for (
        let valueIndex = 1;
        valueIndex < valueScoreCoords.length;
        valueIndex++
      ) {
        context.lineTo(
          valueScoreCoords[valueIndex].x,
          valueScoreCoords[valueIndex].y
        );
      }
      // Go back to first value
      context.lineTo(valueScoreCoords[0].x, valueScoreCoords[0].y);

      context.strokeStyle = COLOR_VALUE_LINES;
      context.lineWidth = valuesLineStrength;
      context.stroke();

      /**
       * DRAW VALUE HIGHLIGHTER
       */

      // Loop through value score coordinates array and draw hightlighting elements
      for (
        let valueIndex = 0;
        valueIndex < valueScoreCoords.length;
        valueIndex++
      ) {
        context.beginPath();
        context.arc(
          valueScoreCoords[valueIndex].x,
          valueScoreCoords[valueIndex].y,
          indicatorRadius,
          0,
          2 * Math.PI
        );
        context.strokeStyle = COLOR_VALUE_TEXT;
        context.lineWidth = lineStrength;
        context.fillStyle = COLOR_VALUE_LINES;
        context.fill();
        context.stroke();
      }

      /**
       * DRAW CATEGORY TEXTS
       */

      valueCount = 0;
      context.font = `${categoryTextSize} Work Sans`;
      context.textBaseline = "middle";
      // Loop through all categories
      for (
        let categoryIndex = 0;
        categoryIndex < sortedCategories.length;
        categoryIndex++
      ) {
        context.fillStyle = COLOR_CATEGORY_TEXT_PALETTE[categoryIndex];
        // Calculate x and y coordinates of average value positions
        let valueUpper =
          valueCount + sortedCategories[categoryIndex].values.length - 1;
        let x = (valueCorners[valueUpper].x + valueCorners[valueCount].x) / 2;
        let y = (valueCorners[valueUpper].y + valueCorners[valueCount].y) / 2;

        /**
         * Note that these are not the actual quadrants known from coordinate systems.
         * they represent the direction of the text, going from 0 to 4 counterclock-wise in the circle
         * e.g:
         * 0: 	east
         * 0.5: southeast
         * 1: 	south
         * 1.5: southwest
         * ...
         */
        let quadrant = 0; //
        let yOffset = 0;

        // Depending on average value position, place text on left / right side
        if (x > xCenter && y > yCenter) {
          quadrant = 0.5; // bottom right
          context.textAlign = "right";
          x = maxWidth;
          yOffset = categoryTextYOffset;
        } else if (x < xCenter && y > yCenter) {
          quadrant = 1.5; // bottom left
          context.textAlign = "left";
          x = 0;
          yOffset = categoryTextYOffset;
        } else if (x < xCenter && y < yCenter) {
          quadrant = 2.5; // top left
          context.textAlign = "left";
          x = 0;
          yOffset = -categoryTextYOffset;
        } else if (x > xCenter && y < yCenter) {
          quadrant = 3.5; // top right
          context.textAlign = "right";
          x = maxWidth;
          yOffset = -categoryTextYOffset;
        }
        y =
          yOffset +
          yCenter +
          size *
            categoryTextOffset *
            Math.sin(
              ((numberOfSides / 4) * quadrant * 2 * Math.PI) / numberOfSides
            );
        context.fillText(sortedCategories[categoryIndex].category_name, x, y);
        valueCount += sortedCategories[categoryIndex].values.length;
      }
      context.textBaseline = "alphabetic";

      /**
       * DRAW VALUE TEXTS
       */

      context.font = `${valueTextSize} Work Sans`;
      context.fillStyle = COLOR_VALUE_TEXT;
      context.textBaseline = "middle";
      // Loop through values array and apply offsets
      for (let valueIndex = 0; valueIndex < sortedValue.length; valueIndex++) {
        let x =
          ((valueCorners[valueIndex].x - xCenter) / size) *
            (size + valueTextOffset) +
          xCenter;
        let y =
          ((valueCorners[valueIndex].y - yCenter) / size) *
            (size + valueTextOffset) +
          xCenter;
        context.textAlign = "center";
        if (
          Math.round(x) < Math.round(xCenter) &&
          Math.round(x) + 1 < Math.round(xCenter)
        ) {
          context.textAlign = "right";
        } else if (
          Math.round(x) > Math.round(xCenter) &&
          Math.round(x) - 1 > Math.round(xCenter)
        ) {
          context.textAlign = "left";
        }
        context.fillText(sortedValue[valueIndex].nature_of_value, x, y);
      }
      context.textBaseline = "alphabetic";
      setIsDrawn(true);
    }
  });

  return (
    <canvas width={maxWidth + "px"} height={maxWidth + "px"} ref={canvasRef} />
  );
};

export default ScoreGraph;
