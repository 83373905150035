import { Grid } from "@mui/material";
import { Text } from "../Atoms/Typography/Text";
import SmileyScale from "../Molecules/SmileyScale";
import { apiBaseUrl } from "../utils/environmentDependencies";

interface SmileyRatingProps {
  questionText: string;
  id: number;
  testId: number;
  secret_key: string;
}

const SmileyRating: React.FC<SmileyRatingProps> = ({
  questionText,
  id,
  testId,
  secret_key,
}) => {
  const handleFeedbackSubmit = (score: number) => {
    let body = { test_id: testId, key: secret_key, score };
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/feedback/score/question/${id}/answer`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }
    )
      .then(() => {})
      .catch((err) => {});
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Text text={questionText} textClass="subHeader" />
      </Grid>
      <Grid item xs={12}>
        <SmileyScale onSubmit={handleFeedbackSubmit} />
      </Grid>
    </Grid>
  );
};

export default SmileyRating;
