import { Grid } from "@mui/material";
import { Text } from "../Atoms/Typography/Text";

interface ScoreSimpleProps {
  scorePercentage: number;
}

const ScoreSimple: React.FC<ScoreSimpleProps> = ({ scorePercentage }) => {
  return (
    <Grid textAlign="center">
      <Text centered component="span" textClass="caption" text={`Trifft `} />
      <Text
        bold
        centered
        component="span"
        textClass="caption"
        text={`${scorePercentage}%`}
      />
      <Text centered component="span" textClass="caption" text={` zu`} />
    </Grid>
  );
};

export default ScoreSimple;
