import styles from "../../scss/modules/mark.module.scss";

interface MarkProps {
  style?: object;
}

const Mark: React.FC<MarkProps> = (props) => {
  return <div style={props && props.style} className={styles.mark} />;
};
export default Mark;
