import { Slider } from "@mui/material";
import React from "react";
import Mark from "../Atoms/AnswerScaleComponents/Mark";
import Rail from "../Atoms/AnswerScaleComponents/Rail";
import Thumb from "../Atoms/AnswerScaleComponents/Thumb";
import { ANSWER_SCALE_MAX, ANSWER_SCALE_MIN } from "../utils/constants";
import { fireGAEvent, GA_CATEGORY_ANSWER } from "../utils/googleAnalytics";
import { ScoreMap } from "../utils/types/test";

interface AnswerScaleProps {
  setScore: (stepNumber: number, scoreMap: ScoreMap[]) => void;
  score?: number;
  step: number;
  setTipIsShown: (tipIsShown: boolean) => void;
}

const AnswerScale: React.FC<AnswerScaleProps> = ({
  setScore,
  score,
  step,
  setTipIsShown,
}) => {
  return (
    <>
      <Slider
        defaultValue={(ANSWER_SCALE_MAX - ANSWER_SCALE_MIN) / 2}
        components={{ Mark: Mark, Thumb: Thumb, Rail: Rail }}
        marks
        min={ANSWER_SCALE_MIN}
        max={ANSWER_SCALE_MAX}
        step={1}
        value={
          score !== null && score !== undefined
            ? score
            : (ANSWER_SCALE_MAX - ANSWER_SCALE_MIN) / 2
        }
        track={false}
        onChange={async (e, value) => {
          fireGAEvent(
            GA_CATEGORY_ANSWER,
            GA_CATEGORY_ANSWER.actions.selectAnswer,
            `Score ${value}`
          );
          if (!Array.isArray(value)) {
            setScore(step - 1, [
              { expressionIndex: 1, score: value },
              {
                expressionIndex: 0,
                score: ANSWER_SCALE_MAX - ANSWER_SCALE_MIN - value,
              },
            ]);
          }
          setTipIsShown(false);
        }}
      />
    </>
  );
};

export default AnswerScale;
