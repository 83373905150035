import { Grid } from "@mui/material";
import { useState } from "react";
import styles from "../scss/modules/smileyscale.module.scss";
import clsx from "clsx";
import { Text } from "../Atoms/Typography/Text";

interface SmileyScaleProps {
  onSubmit: (score: number) => void;
}

const SmileyScale: React.FC<SmileyScaleProps> = ({ onSubmit }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [transitionEnded, setTransitionEnded] = useState<boolean>(false);
  return (
    <Grid container justifyContent="space-between">
      {transitionEnded ? (
        <Grid item xs={12}>
          <Text textClass="small" text="Danke für deine ehrliche Meinung!" />
        </Grid>
      ) : (
        [
          <Grid key="0" item style={{ textAlign: "center" }}>
            <div className={styles.smileyContainer}>
              <img
                className={clsx(
                  styles.smiley,
                  submitted ? styles.smileyBegoneLeft : undefined
                )}
                onClick={() => {
                  setSubmitted(true);
                  onSubmit(0);
                }}
                src="/SmileyRatings/0.png"
                alt="very bad"
              />
            </div>
          </Grid>,
          <Grid key="1" item style={{ textAlign: "center" }}>
          <div className={styles.smileyContainer}>
            <img
              className={clsx(
                styles.smiley,
                submitted ? styles.smileyBegoneLeft : undefined
              )}
              onClick={() => {
                setSubmitted(true);
                onSubmit(1);
              }}
              src="/SmileyRatings/1.png"
              alt="bad"
            />
          </div>
        </Grid>,
        <Grid key="2" item style={{ textAlign: "center" }}>
        <div className={styles.smileyContainer}>
          <img
            className={clsx(
              styles.smiley,
              submitted ? styles.smileyBegoneLeft : undefined
            )}
            onClick={() => {
              setSubmitted(true);
              onSubmit(2);
            }}
            src="/SmileyRatings/2.png"
            alt="good"
          />
        </div>
      </Grid>,
          <Grid key="3" item style={{ textAlign: "center" }}>
            <div className={styles.smileyContainer}>
              <img
                onTransitionEnd={(e) => {
                  if (e.propertyName === "margin-left")
                    setTransitionEnded(true);
                }}
                onClick={() => {
                  setSubmitted(true);
                  onSubmit(3);
                }}
                className={clsx(
                  styles.smiley,
                  submitted ? styles.smileyBegoneLeft : undefined
                )}
                src="/SmileyRatings/3.png"
                alt="very good"
              />
            </div>
          </Grid>,
        ]
      )}
    </Grid>
  );
};

export default SmileyScale;
