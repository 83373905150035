import React from "react";
import styles from "../../scss/modules/typography.module.scss";
import clsx from "clsx";

interface TextProps {
  text: string | undefined;
  textClass?: "caption" | "form" | "small" | "subHeader" | "expression" | "h1";
  component?: "span" | "p";
  bold?: boolean;
  centered?: boolean;
  color?: "default" | "light" | "colored";
  style?: React.CSSProperties;
}

export const Text: React.FC<TextProps> = ({
  text,
  textClass,
  component,
  bold,
  centered,
  color,
  style,
}) => {
  return (
    <>
      {component === "span" ? (
        <span
          className={clsx(
            styles.typography,
            textClass && styles[textClass],
            bold && styles.bold,
            color && styles[color]
          )}
        >
          {text}
        </span>
      ) : (
        <p
          style={style}
          className={clsx(
            styles.typography,
            textClass && styles[textClass],
            bold && styles.bold,
            centered && styles.centered,
            color && styles[color]
          )}
        >
          {text}
        </p>
      )}
    </>
  );
};
