let apiBaseUrl: string = "";
let gAMeasurementId: string = "";
let jobPortalUrl: string = "";
if (process.env.REACT_APP_ENV === "production") {
  apiBaseUrl = "https://recruit.searchtalent.de";
  jobPortalUrl = "https://jobs.searchtalent.de";
  gAMeasurementId = "G-TB0E704BRE";
} else if (process.env.REACT_APP_ENV === "staging") {
  apiBaseUrl = "https://recruit-staging.searchtalent.de";
  jobPortalUrl = "https://jobs-staging.searchtalent.de";
} else {
  apiBaseUrl = "http://localhost:4560";
  jobPortalUrl = "http://localhost:3001";
}

export { apiBaseUrl, gAMeasurementId, jobPortalUrl };
