import { Grid } from "@mui/material";
import { Text } from "./Typography/Text";
import styles from "../scss/modules/expression.module.scss";

interface ExpressionProps {
  content: string | undefined;
  score: number;
}

const Expression: React.FC<ExpressionProps> = ({ content }) => {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignItems="center"
      className={styles.container}
    >
      <Text text={content} textClass="expression" color="light" />
    </Grid>
  );
};

export default Expression;
