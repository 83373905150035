import { TestStepModel } from "./types/test";
import { apiBaseUrl } from "./environmentDependencies";

export function shuffle<Type>(array: Array<Type>): Array<Type> {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const handleAnswerSubmit: (
  stepData: TestStepModel,
  testId: number,
  setScoreId: (
    stepNumber: number,
    expressionIndex: number,
    scoreID: number
  ) => void,
  step: number
) => Promise<void>[] = function (stepData, testId, setScoreId, step) {
  return stepData.test_expressions.map((expression, index) => {
    return new Promise(async (resolve, reject) => {
      let reqBody = {
        test_id: testId,
        step_id: stepData.id,
        expression_id: expression.id,
        score: expression.expression_values.score,
      };
      if (!expression.expression_values.score_id) {
        // POST request
        try {
          let response = await fetch(
            `${apiBaseUrl}/recunited/questionnaire/test_score`,
            {
              method: "POST",
              body: JSON.stringify(reqBody),
              headers: { "Content-Type": "application/json" },
            }
          );
          let responseJSON = await response.json();
          if (response.status === 200) {
            setScoreId(step, index, responseJSON.id);
            resolve();
          }
        } catch (err) {
          console.log(err);
          reject();
        }
      } else {
        // PUT request
        try {
          let reqBody = {
            expression_id: expression.id,
            score: expression.expression_values.score,
          };
          let response = await fetch(
            `${apiBaseUrl}/recunited/questionnaire/test_score/${expression.expression_values.score_id}`,
            {
              method: "PUT",
              body: JSON.stringify(reqBody),
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 200) {
            resolve();
          }
        } catch (err) {
          console.log(err);
          reject();
        }
      }
    });
  });
};
