import { Grid } from "@mui/material";
import { Text } from "../Atoms/Typography/Text";

const AlreadyDone: React.FC = () => {
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignContent="center"
      spacing={3}
    >
      <Grid item xs={10}>
        <Text
          textClass="subHeader"
          text="Sieht so aus, als hättest du deinen Test schon abgeschlossen. Schau mal in dein Email-Postfach."
          centered
        />
      </Grid>
    </Grid>
  );
};

export default AlreadyDone;
