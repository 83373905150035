import { Alert, Grid, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import Expression from "../Atoms/Expression";
import { Button } from "../Atoms/Button/Button";
import { TEST_TIPS_ARR } from "../utils/constants";
import { Text } from "../Atoms/Typography/Text";
import { RadioButtons } from "../Atoms/Button/RadioButtons";
import { handleAnswerSubmit } from "../utils/functions";
import { ToAnswerCompanyProps } from "../utils/types/test";

export const ToAnswerCompany: React.FC<ToAnswerCompanyProps> = ({
  content,
  step,
  setStep,
  setScore,
  stepData,
  setScoreId,
  testId,
}) => {
  const SHOW_AFTER_MS = 10 * 1000; // sec * milisec
  const [testTip, setTestTip] = useState<string>(
    "Es gibt kein richtig oder falsch, antworte aus dem Bauch heraus."
  );
  const [isTipShown, setTipIsShown] = useState<boolean>(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>(
    setTimeout(() => {}, 0)
  );

  const randomizeTips = (arr: string[]) => {
    setTestTip(arr[Math.floor(Math.random() * arr.length)]);
  };

  const [errorOpen, setErrorOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  // show tip after not using slider for a while
  useEffect(() => {
    clearTimeout(timer);
    setTipIsShown(false);
    setTimer(
      setTimeout(() => {
        setTipIsShown(true);
      }, SHOW_AFTER_MS)
    );
    randomizeTips(TEST_TIPS_ARR);
  }, [step]);

  const scoreArr = stepData.test_expressions.map(
    (expression) => expression.expression_values.score
  );
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item xs={12}>
        <Text text={stepData.test_step_title} centered textClass="expression" />
      </Grid>
      <Grid container item xs={12}>
        {stepData.test_expressions.map((expression, index) => {
          return (
            <Grid item container key={index}>
              <Grid item container xs={12} sm={12} alignItems="stretch">
                <Grid item xs={12}>
                  <Expression
                    content={expression.expression_text}
                    score={expression.expression_values.score}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} alignItems="stretch">
                  <RadioButtons
                    expressionIndex={index}
                    stepNumber={step - 1}
                    setScore={setScore}
                    score={expression.expression_values.score}
                    disableMin={
                      scoreArr.includes(0) &&
                      expression.expression_values.score !== 0
                    }
                    disableMax={
                      scoreArr.includes(7) &&
                      expression.expression_values.score !== 7
                    }
                  />
                </Grid>
              </Grid>
              ;
              <Grid item xs={12} />
            </Grid>
          );
        })}
      </Grid>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Huch! Etwas ist schiefgelaufen. Bitte versuche es nochmal
        </Alert>
      </Snackbar>
      <Grid item container xs={12} justifyContent="space-evenly">
        <Button
          className="back"
          disabled={false}
          secondary
          clickHandler={() => {
            setStep(step - 1);
          }}
        >
          Zurück
        </Button>
        <Button
          className="next"
          disabled={
            stepData.test_expressions[0].expression_values.score ===
              undefined ||
            stepData.test_expressions[1].expression_values.score ===
              undefined ||
            stepData.test_expressions[2].expression_values.score ===
              undefined ||
            stepData.test_expressions[3].expression_values.score ===
              undefined ||
            stepData.test_expressions[0].expression_values.score === null ||
            stepData.test_expressions[1].expression_values.score === null ||
            stepData.test_expressions[2].expression_values.score === null ||
            stepData.test_expressions[3].expression_values.score === null
          }
          clickHandler={async () => {
            try {
              await handleAnswerSubmit(stepData, testId, setScoreId, step - 1);
              setStep(++step);
            } catch (e) {
              setErrorOpen(true);
            }
          }}
        >
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
};
