import { Grid } from "@mui/material";
import React from "react";
import { ScreenProps } from "../utils/types/screen";
import { Diamond } from "../Atoms/Diamond/Diamond";
import CanvasContainer from "../Atoms/Diamond/CanvasContainer";
import { Button } from "../Atoms/Button/Button";
import { fireGAEvent, GA_CATEGORY_ADVANCE } from "../utils/googleAnalytics";
import { Text } from "../Atoms/Typography/Text";

export const WelcomePage: React.FC<ScreenProps> = ({
  step,
  setStep,
  isCandidate,
}) => {
  const handleClick = () => {
    fireGAEvent(GA_CATEGORY_ADVANCE, GA_CATEGORY_ADVANCE.actions.testStarted);
    setStep(++step);
  };

  return (
    <Grid data-testid="start" item container spacing={1}>
      {isCandidate ? (
        <>
          <Grid item xs={12}>
            <Text
              text={
                "Du bist auf der Suche nach einem Job, der dich erfüllt, glücklich macht und in dem du mehr du selbst sein kannst? 😍"
              }
              textClass="subHeader"
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              component="span"
              text={
                "Dann ist der Cultural-Fit-Test von Recunited genau das Richtige für dich. "
              }
              textClass="small"
            />
            <Text
              component="span"
              text={
                "Das Ergebnis wird dir dabei helfen, deine persönlichen Werte, Wünsche und Vorstellungen für dein Berufsleben herauszufinden. ⭐️💎"
              }
              color="light"
              textClass="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              text={
                "Der Test dauert lediglich 5 Minuten und führt dich in 16 kurzen Aussagen zu deinem individuellen Ergebnis."
              }
              textClass="small"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Text
              text={"Hallo liebes Team und willkommen zum Cultural-Fit-Test"}
              textClass="subHeader"
            />
            <Text text={"Was ist unser Wertetest?"} textClass="subHeader" />
            <Text
              text={
                "Unser Cultural-Fit-Test ist ein Instrument, der die verschiedenen Aspekte eurer individuellen Teamkultur messbar und damit greifbar macht. Euer Ergebnis wird mit denen der Bewerber:innen gematched und verrät, wie gut die Kandidat:innen persönlich zu euch ins Team passen würde."
              }
              textClass="small"
            />
            <Text text={"Was musst du machen?"} textClass="subHeader" />
            <Text
              text={
                "Ganz einfach: Beantworte 16 kurze Aussagen anhand deiner persönlichen Zustimmung. Bei jedem abgefragten Wert kannst du den Grad deiner Übereinstimmung/Ablehnung individuell von 0 bis 10 bewerten. Der Test dauert ungefähr 5 Minuten."
              }
              textClass="small"
            />
            <Text text={"! Wichtig !"} textClass="subHeader" />
            <Text
              text={
                "Es gibt hier kein richtig und kein falsch, beantworte die Aussagen ganz intuitiv und ehrlich. Die Kandidaten sehen euer Ergebnis nicht im Detail, sondern nur die Übereinstimmungshöhe. Nur so kann ein echtes Matching stattfinden :leichtes_lächeln:"
              }
              textClass="small"
            />
          </Grid>
        </>
      )}
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        style={{ maxHeight: 150 }}
      >
        <CanvasContainer>
          <Diamond type={"shiny"} />
        </CanvasContainer>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Button className="steps" disabled={false} clickHandler={handleClick}>
          Los geht's!
        </Button>
      </Grid>
    </Grid>
  );
};
