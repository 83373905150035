import { AppBar, Toolbar } from "@mui/material";
import Logo from "./Typography/Logo";

interface AppHeaderProps {
  setInnerRef?: (ref: HTMLDivElement | null) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ setInnerRef }) => {
  return (
    <AppBar
      ref={(newRef) => setInnerRef && setInnerRef(newRef)}
      position="static"
    >
      <Toolbar>
        <Logo image="/recunited_logo_color_850x160.png" title="Recunited" />
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
