import styles from "../../scss/modules/thumb.module.scss";
import clsx from "clsx";

const Thumb: React.FC = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ownerState, ...filteredProps } = props;
  return (
    <span {...filteredProps} className={styles.thumb}>
      {props.children}
      <div className={styles.thumbPartContainer}>
        <div className={styles.layer3ThumbLeft} />
        <div className={styles.layer3ThumbCenter} />
        <div className={styles.layer3ThumbRight} />
      </div>
      <div className={styles.thumbPartContainer}>
        <div className={styles.layer2ThumbLeft} />
        <div className={styles.layer2ThumbCenter} />
        <div className={styles.layer2ThumbRight} />
      </div>
      <div className={clsx(styles.thumbPartContainer, styles.boxShadow)}>
        <div className={styles.layer1ThumbLeft} />
        <div className={styles.layer1ThumbCenter} />
        <div className={styles.layer1ThumbRight} />
      </div>
    </span>
  );
};

export default Thumb;
