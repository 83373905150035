import ReactGA from "react-ga4";

type GoogleAnalyticsActionAnswer = "selectAnswer";

type GoogleAnalyticsActionAdvance = "testStarted" | "goNext" | "goBack";

type GoogleAnalyticsActionForm = "submitForm";

export type GoogleAnalyticsAction =
  | GoogleAnalyticsActionAnswer
  | GoogleAnalyticsActionAdvance
  | GoogleAnalyticsActionForm;

export interface GoogleAnalyticsCategory<
  ActionType extends GoogleAnalyticsAction
> {
  name: string;
  actions: { [key: string]: ActionType };
}

export const GA_CATEGORY_ANSWER: GoogleAnalyticsCategory<GoogleAnalyticsActionAnswer> =
  {
    name: "question",
    actions: {
      selectAnswer: "selectAnswer",
    },
  };

export const GA_CATEGORY_ADVANCE: GoogleAnalyticsCategory<GoogleAnalyticsActionAdvance> =
  {
    name: "advance",
    actions: {
      testStarted: "testStarted",
      goNext: "goNext",
      goBack: "goBack",
    },
  };

export const GA_CATEGORY_FORM: GoogleAnalyticsCategory<GoogleAnalyticsActionForm> =
  {
    name: "form",
    actions: {
      submitForm: "submitForm",
    },
  };

export function fireGAEvent<ActionType extends GoogleAnalyticsAction>(
  category: GoogleAnalyticsCategory<ActionType>,
  action: ActionType,
  label?: string
): void {
  ReactGA.event({ category: category.name, action, label });
}

export function fireGAPageView(): void {
  ReactGA.send("pageview");
}
