import { useEffect, useRef, useState } from "react";
import styles from "../../scss/modules/rail.module.scss";

const GLOW_OFFSET = 5;
const CURVATURE_MOD = 0.4;

const calculateClipRectXMod = function (
  minValue: number,
  maxValue: number,
  curValue: number
) {
  if (curValue === (maxValue - minValue) / 2) {
    return 0.5 - 0.5 / (maxValue - minValue);
  } else if (curValue < (maxValue - minValue) / 2) {
    return curValue / (maxValue - minValue);
  } else {
    return 0.5 - 0.5 / (maxValue - minValue);
  }
};

const calculateClipRectWidthMod = function (
  minValue: number,
  maxValue: number,
  curValue: number
) {
  if (curValue === (maxValue - minValue) / 2) {
    return 1 / (maxValue - minValue);
  } else if (curValue < (maxValue - minValue) / 2) {
    return 0.5 / (maxValue - minValue) + 0.5 - curValue / (maxValue - minValue);
  } else {
    return curValue / (maxValue - minValue) - 0.5 + 0.5 / (maxValue - minValue);
  }
};

const Rail: React.FC = (props: any) => {
  const maxValue = props.ownerState.max;
  const minValue = props.ownerState.min;
  const curValue = props.ownerState.value;
  const clipXMod = calculateClipRectXMod(minValue, maxValue, curValue);
  const clipWidthMod = calculateClipRectWidthMod(minValue, maxValue, curValue);
  const [svgHeight, setSvgHeight] = useState<number>(0);
  const [svgWidth, setSvgWidth] = useState<number>(0);
  const svgRef = useRef<SVGSVGElement>(null);
  const svgPath = `M${0 + GLOW_OFFSET},${0 + GLOW_OFFSET} Q${svgWidth / 2},${
    svgHeight * (1 - CURVATURE_MOD)
  } ${svgWidth - GLOW_OFFSET},${0 + GLOW_OFFSET} L${svgWidth - GLOW_OFFSET},${
    svgHeight - GLOW_OFFSET
  } Q${svgWidth / 2},${svgHeight * CURVATURE_MOD} ${0 + GLOW_OFFSET},${
    svgHeight - GLOW_OFFSET
  } L${0 + GLOW_OFFSET},${0 + GLOW_OFFSET}`;

  // Required hack for proper rerender on ref update
  useEffect(() => {
    if (svgRef && svgRef.current) {
      setSvgWidth(svgRef.current.clientWidth);
      setSvgHeight(svgRef.current.clientHeight);
    }
  }, [svgRef]);

  return (
    <div className={styles.rail}>
      <svg
        ref={svgRef}
        className={styles.railSvg}
        viewBox={
          svgWidth && svgHeight ? `0 0 ${svgWidth} ${svgHeight}` : "0 0 0 0"
        }
        preserveAspectRatio="none"
      >
        {svgWidth && svgHeight && (
          <path d={svgPath} className={styles.railPath} />
        )}
      </svg>
      <svg
        className={styles.railSvg}
        viewBox={
          svgWidth && svgHeight ? `0 0 ${svgWidth} ${svgHeight}` : "0 0 0 0"
        }
        preserveAspectRatio="none"
      >
        <defs>
          <clipPath id="cutOff">
            <rect
              x={svgWidth ? clipXMod * svgWidth : "0"}
              y="0"
              width={svgWidth ? clipWidthMod * svgWidth : "0"}
              height={svgHeight ? svgHeight : "0"}
            />
          </clipPath>
          <filter id="softGlow" height="200%" width="200%" x="-75%" y="-75%">
            <feMorphology
              operator="dilate"
              radius="1"
              in="SourceAlpha"
              result="thicken"
            />
            <feGaussianBlur in="thicken" stdDeviation="1" result="blurred" />
            <feFlood floodColor="rgb(174,237,232)" result="glowColor" />
            <feComposite
              in="glowColor"
              in2="blurred"
              operator="in"
              result="softGlow_colored"
            />
            <feMerge>
              <feMergeNode in="softGlow_colored" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        {svgWidth && svgHeight && (
          <path
            d={svgPath}
            className={styles.railPathActive}
            filter="url(#softGlow)"
            clipPath="url(#cutOff)"
          />
        )}
      </svg>
    </div>
  );
};

export default Rail;
