import { Grid, Radio, useMediaQuery } from "@mui/material";
import React from "react";
import { RadioButtonsProps } from "../../utils/types/test";
import { Text } from "../Typography/Text";
import { useTheme } from "@mui/material/styles";

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  expressionIndex,
  stepNumber,
  setScore,
  score,
  disableMax,
  disableMin,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScore(stepNumber, [
      {
        expressionIndex,
        score: parseInt(event.target.value),
      },
    ]);
  };

  return (
    <Grid container item justifyContent="center" alignContent="space-evenly">
      <Text text={"0"} textClass="small" color="default" bold />
      {Array(8)
        .fill(1)
        .map((_, index) => {
          return (
            <Radio
              checked={score === index}
              onChange={handleChange}
              value={index}
              name="radio-buttons"
              key={index}
              disabled={
                (index === 7 && disableMax) || (index === 0 && disableMin)
              }
              size={isMobile ? "small" : "medium"}
            />
          );
        })}
      <Text text={"7"} textClass="small" color="default" bold />
    </Grid>
  );
};
