import { Grid, Theme } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { apiBaseUrl } from "../utils/environmentDependencies";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Text } from "../Atoms/Typography/Text";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("sm")]: {
      container: {
        minHeight: "100vh",
        justifyContent: "center",
        padding: "2rem",
      },
    },
    [theme.breakpoints.up("sm")]: {
      container: {
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "flex-end",
        padding: "2rem",
      },
    },
  })
);

interface GettingTestReadyProps {
  isCandidateTest: Boolean;
}

const GettingTestReady: React.FC<GettingTestReadyProps> = ({
  isCandidateTest,
}) => {
  const { teamId } = useParams();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("job_id");
  const application = searchParams.has("application");
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `${apiBaseUrl}/recunited/questionnaire/test_definitions?active=true&is_candidate=${isCandidateTest}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Take first test definition and create test for now
        let body = {
          test_definition_id: data.test_definitions[0].id,
          ...(teamId && { team_id: parseInt(teamId, 10) }),
        };
        fetch(`${apiBaseUrl}/recunited/questionnaire/test`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((data) => {
            navigate(
              `/test?test_id=${data.id}&key=${data.key}${
                jobId ? `&job_id=${jobId}` : ""
              }${application ? `&application=${application}` : ""}`
            );
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid
      className={classes.container}
      container
      justifyContent="center"
      alignContent="center"
    >
      <Grid item xs={10}>
        <Text
          textClass="subHeader"
          centered
          text="Wir erstellen deinen Test. Gleich geht's los"
        />
      </Grid>
    </Grid>
  );
};

export default GettingTestReady;
