import { Grid, Link } from "@mui/material";
import { Button } from "../Atoms/Button/Button";
import { Text } from "../Atoms/Typography/Text";
import { jobPortalUrl } from "../utils/environmentDependencies";
interface ReturnToJobProps {
  jobId?: string;
  matchingScoreAverage?: number;
  isApplication: boolean;
}

const ReturnToJob: React.FC<ReturnToJobProps> = ({
  jobId,
  matchingScoreAverage,
  isApplication,
}) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <div style={{ textAlign: "center" }}>
          <Text textClass="subHeader" text="Zufrieden mit deinem Ergebnis?" />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Link
          href={`${jobPortalUrl}/jobdetails/${jobId}?matchingScore=${matchingScoreAverage}${
            isApplication ? `&show-application-dialog` : ""
          }`}
        >
          <div id="fdddf" style={{ display: "flex", justifyContent: "center" }}>
            <Button className="submit" disabled={false}>
              Mit der Bewerbung fortfahren
            </Button>
          </div>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ReturnToJob;
