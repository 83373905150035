import { Grid, LinearProgress } from "@mui/material";
import React from "react";
import styles from "../scss/modules/progress.module.scss";

interface ProgressProps {
  step: number;
  stepsTotal: number;
}

const Progress: React.FC<ProgressProps> = ({ step, stepsTotal }) => {
  return (
    <>
      <LinearProgress
        classes={{
          colorPrimary: styles.colorPrimary,
          barColorPrimary: styles.barColorPrimary,
        }}
        value={(100 * step) / (stepsTotal - 1)}
        variant={"determinate"}
        color="primary"
        className={styles.progressBar}
      />
      <Grid
        container
        className={styles.progressBar}
        justifyContent="space-between"
      >
        {Array(stepsTotal)
          .fill(1)
          .map((_, i) => (
            <Grid item key={i} className={styles.seperator} />
          ))}
      </Grid>
    </>
  );
};

export default Progress;
